body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:overlay;
  background-image: url("BgImage.jfif");
}

::-webkit-scrollbar-track{
  background:transparent;
  width:10px;
}
::-webkit-scrollbar{
  background:transparent;
  width:10px;
}
::-webkit-scrollbar-thumb{
  background:#ff0058;
  border-radius:44px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mx-17{
  margin-right: 17px;
}
.my-17{
  margin-top: 17px;
}
.px-8{
  padding: 17px;
  border-radius: 44px;
}
button{
  font-weight: 900;
  font-size: 14px
}
.containerMain{
  background-color:rgba(255,255,255,0.2);
  backdrop-filter: blur(17px);
  border-radius: 44px;
  padding-right:17px;
  padding-left:17px;
  padding-bottom:8px;
  padding-top:8px;
  margin-top: 17px;
  margin-bottom: 17px;
}
h1{
  color: #fff;
}
h2{
  color: #fff;
}
p{
  color: #fff;
}
#exampleFormControlTextarea1{
  color:#ff0058;
  background-color:rgba(255,255,255,0.4);
  backdrop-filter: blur(17px);
  border-radius: 44px;
  font-weight: 600;
  font-size:1.7rem;
  padding:26px;
}
#exampleFormControlTextarea1::placeholder{
  color:#ffbc00;
  font-size:2rem;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  text-align: center;
}